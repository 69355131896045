<template>
  <div class="bgImage centerAll">
    <div>
      <a
        class="material-card-content centerAll"
        :href="$websiteUrl"
      >
        <img
          width="300px"
          src="@/assets/img/logoWhite.svg"
          alt="Boardgent logo"
        >
      </a>
      <card
        external-card="card container material-card card-form-container"
        internal-card="material-card-content"
        url-help=""
      >
        <section
          slot="body"
          class="modal-card-body"
          style="text-align: center"
        >
          <p
            style="margin-top: 12px;"
            class="title"
          >
            {{ status }}
          </p>
          <footer
            class="card-footer has-text-centered padding-card-footer"
            style="text-align: center"
          >
            <p
              style="margin-top: 12px;width:100%; text-align: center"
            >
              {{ message }}
            </p>
          </footer>
        </section>
      </card>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import toastMessage from '@/helpers/toastMessage';
import card from '@/components/cross/Card.vue';
import vtulEnums from '../../../cross/index';

const { managementAction } = vtulEnums.enum.authentication;

export default {
  name: 'VerifyEmail',
  metaInfo: {
    title: 'Verify Email',
  },
  components: {
    card,
  },
  data() {
    return {
      error: undefined,
      status: 'Verifying Email',
      message: 'Please wait',
    };
  },
  mounted() {
    this.verifyEmail();
  },

  methods: {
    ...mapActions('auth-management', { validateToken: 'create' }),

    async verifyEmail() {
      try {
        const response = await this.validateToken({
          action: managementAction.VALIDATE_VERIFY_EMAIL.value,
          token: this.$route.params.token,
        });
        if (response) {
          this.status = 'Your email has been confirmed.';
          this.message = 'You can close this tab now';
          toastMessage.showSuccess(this.message);
        } else {
          toastMessage.showError('An unexpected error, please try again later');
          this.status = 'An unexpected error';
          this.message = 'Please try again later';
        }
      } catch (err) {
        toastMessage.showError(err.message);
        this.status = 'Please try again later';
        this.message = err.message;
      }
    },
  },
};
</script>
